import React, { useState } from 'react'
import { Segment, Container, Grid, Button } from 'semantic-ui-react'

import '../CSS/blog.css'
import { Link } from 'gatsby'

const Blog = ({ articles }) => {
  const [nrVisibleArticles, setNrVisibleArticles] = useState(3)

  const visibleArticles = articles.slice(0, Math.min(articles.length, nrVisibleArticles))

  const onViewMoreClicked = () => {
    const remainingNrArticles = articles.length - visibleArticles.length
    setNrVisibleArticles(Math.min(visibleArticles.length + 3, visibleArticles.length + remainingNrArticles))
  }

  return (
    <Segment basic className='blog-segment' name='blog'>
      <Container className='blog-container'>
        <div style={{ textAlign: 'center', paddingBottom: 24 }}>
          <h2 className='underlineHeader'>Blog</h2>
        </div>
        <Grid stackable>
          {
            visibleArticles.map(({ node: { title, summary, featureImageUrl, fields: { slug } } }, index) => (
              <Grid.Row key={index} centered>
                <Grid.Column width={5}>
                  <Link to={slug} >
                    <div className='blog-img-container'>
                      <img className='blog-img' src={featureImageUrl} />
                    </div>
                  </Link>
                </Grid.Column>
                <Grid.Column width={11} className='article-details'>
                  <Grid.Row className='darkOnLightTextColor'>
                    <Link to={slug}>
                      <h2>
                        {title}
                      </h2>
                    </Link>
                  </Grid.Row>
                  <Grid.Row className='blog-summary'>
                    {summary}
                  </Grid.Row>
                  <Grid.Row className='read-more'>
                    <Link to={slug}>
                      Read more
                    </Link>
                  </Grid.Row>
                </Grid.Column>
              </Grid.Row>
            ))
          }
          {
            visibleArticles.length !== articles.length && (
              <Grid.Row>
                <Grid.Column textAlign='center'>
                  <Button onClick={onViewMoreClicked}>
                    View More
                  </Button>
                </Grid.Column>
              </Grid.Row>
            )
          }
        </Grid>
      </Container>
    </Segment>
  )
}

export default Blog