import React from 'react'
import { Link } from 'react-scroll'
import { Menu } from 'semantic-ui-react'

const CustomMenuItem = ({ to, onClick, name, offset }) => {
  return (
    <Link
      to={to}
      onClick={onClick}
      smooth={true}
      offset={offset}
      duration={500}>
      <Menu.Item
        name={name}
        className='customItem'
      />
    </Link>
  )
}

export default CustomMenuItem