import React, { useState, useRef } from 'react';
import { Container, Grid, Form, Segment, Image, Button } from 'semantic-ui-react';
import NumberFormat from 'react-number-format';
import { store } from 'react-notifications-component';

import '../CSS/contact.css';
import '../CSS/formCSS.css';

import squareOne from '../images/fsquareOne.svg';
import squareTwo from '../images/fsquareTwo.svg';
import squareThree from '../images/fsqaureThree.svg';
import squareFour from '../images/fsquareFour.svg';

const axios = require('axios').default;
const url = 'https://us-central1-filamind-io.cloudfunctions.net/sendContactUsEmail'

const Contact = () => {
  const nameRef = useRef('')
  const emailRef = useRef('')
  const phoneRef = useRef('')
  const messageRef = useRef('')

  const [frmInput, setFrmInput] = useState({
    name: {
      value: '', forCss: 'none', isValid: false
    },
    email: {
      value: '', forCss: 'none', isValid: false
    },
    phoneNumber: {
      value: ''
    },
    msg: {
      value: '', forCss: 'none', isValid: false
    }
  });

  const handleFormSubmit = e => {
    if (!verifyForm()) return;

    console.log({ phone: phoneRef.current.value })


    axios.post(url, {
      fromEmail: emailRef.current.value,
      name: nameRef.current.value,
      message: messageRef.current.value,
      phone: phoneRef.current.value
    }).then(res => {
      store.addNotification({
        title: "Success!",
        message: "Email was sent successfully!",
        type: "success",
        insert: "bottom",
        container: "bottom-right",
        animationIn: ["animated", "fadeIn"],
        animationOut: ["animated", "fadeOut"],
        dismiss: {
          duration: 5000,
        }
      });

      // Clear fields
      nameRef.current.value = ''
      emailRef.current.value = ''
      phoneRef.current.value = ''
      messageRef.current.value = ''
    }).catch(error => {
      store.addNotification({
        title: "Oops!",
        message: "There was a problme sending this email 🙈",
        type: "warning",
        insert: "bottom",
        container: "bottom-right",
        animationIn: ["animated", "fadeIn"],
        animationOut: ["animated", "fadeOut"],
        dismiss: {
          duration: 5000,
        }
      });
    })
  }

  const onNameChange = (trgValue) => {
    let css = 'none';
    let validation = true;

    if (trgValue === '') {
      css = 'inline';
      validation = false;
    }

    setFrmInput(prev => ({
      name: { value: trgValue, forCss: css, isValid: validation },
      email: { ...prev.email },
      phoneNumber: { ...prev.phoneNumber },
      msg: { ...prev.msg }
    }));
  };

  const onEmailChange = (trgValue) => {
    let css = 'none';
    let validation = true;

    if (trgValue === '') {
      css = 'inline';
      validation = false;
    }

    setFrmInput(prev => ({
      email: { value: trgValue, forCss: css, isValid: validation },
      name: { ...prev.name },
      phoneNumber: { ...prev.phoneNumber },
      msg: { ...prev.msg }
    }));
  };

  const onPhoneChange = (trgValue) => {
    setFrmInput(prev => ({
      phoneNumber: { value: trgValue },
      email: { ...prev.email },
      name: { ...prev.name },
      msg: { ...prev.msg }
    }));
  };

  const onMsgChange = (trgValue) => {
    let css = 'none';
    let validation = true;

    if (trgValue === '') {
      css = 'inline';
      validation = false;
    }

    setFrmInput(prev => ({
      msg: { value: trgValue, forCss: css, isValid: validation },
      name: { ...prev.name },
      phoneNumber: { ...prev.phoneNumber },
      email: { ...prev.email }
    }));
  };

  const verifyForm = () => {
    if (frmInput.name.isValid && frmInput.email.isValid && frmInput.msg.isValid) return true;

    if (frmInput.name.value === '') { onNameChange('') };
    if (frmInput.email.value === '') { onEmailChange('') };
    if (frmInput.msg.value === '') { onMsgChange('') };

    return false;
  };

  return (
    <Segment basic id='contactSegment' name='contact'>
      <Image src={squareOne} size='small' id='sqrOne' />
      <Image src={squareTwo} size='small' id='sqrTwo' />
      <Image src={squareThree} size='small' id='sqrThree' />
      <Image src={squareFour} size='small' id='sqrFour' />
      <Container>
        <Grid>
          <Grid.Row>
            <Grid.Column textAlign='center' verticalAlign='middle'>
              <h1 className='invertedUnderlineHeader'>Tell us about your project</h1>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column textAlign='center' verticalAlign='middle'>
              <p style={{ fontSize: '1.5em', color: '#EFEFEF' }}>
                Contact us through email below, or simply call us
                at (613) 262-9562.
                            </p>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row centered>
            <Grid.Column>
              <Form onSubmit={handleFormSubmit} method='POST' id='form'>
                <Form.Field>
                  <label className='formLbl'>
                    Contact Name* <span style={{ display: frmInput.name.forCss }} className='errorMsg'>A name is required!</span>
                  </label>
                  <input
                    onBlur={(e) => onNameChange(e.target.value)}
                    ref={el => nameRef.current = el}
                    placeholder='Sorin Tapuc'
                    className='inputBg' />
                </Form.Field>
                <Form.Group widths='2'>
                  <Form.Field>
                    <label className='formLbl'>
                      Email* <span style={{ display: frmInput.email.forCss }} className='errorMsg'>An email address is required!</span>
                    </label>
                    <input
                      onBlur={(e) => onEmailChange(e.target.value)}
                      ref={el => emailRef.current = el}
                      type='text'
                      placeholder='username@provider.ca'
                      className='inputBg' />
                  </Form.Field>
                  <Form.Field>
                    <label className='formLbl'>Phone number</label>
                    <NumberFormat
                      onBlur={(e) => onPhoneChange(e.target.value)}
                      className='inputBg'
                      getInputRef={el => phoneRef.current = el}
                      placeholder='(###) ### - ###'
                      format="(###) ###-####"
                      mask="#" />
                  </Form.Field>
                </Form.Group>
                <Form.Field>
                  <label className='formLbl'>
                    Message* <span style={{ display: frmInput.msg.forCss }} className='errorMsg'>A message is required!</span>
                  </label>
                  <textarea
                    onBlur={(e) => onMsgChange(e.target.value)}
                    ref={el => messageRef.current = el}
                    placeholder="Tell us more about your project..."
                    rows="4"
                    className='inputBg'></textarea>
                </Form.Field>
                <Button id='subBtn' type='submit'>Get Started</Button>
              </Form>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Container>
    </Segment>
  );
};

export default Contact;