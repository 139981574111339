import React, { useState } from 'react';
import { Menu } from 'semantic-ui-react';
import { Link } from 'react-scroll';
import { HamburgerSqueeze } from 'react-animated-burgers';

import logo from '../../images/logo.png';

import '../../CSS/mobileMenu.css';
import CustomMenuItem from './CustomMenuItem';

const MobileMenu = ({ menuItems }) => {
  const [isVisible, setVisible] = useState(false);
  const [isAnimated, setAnimStatus] = useState(false);
  const [expandCss, setDirection] = useState('');

  let handleClickAnimation = () => {
    if (isAnimated) {
      console.log('Its animated')
    } else {
      isVisible ? setVisible(false) : setVisible(true);
      isVisible ? setDirection('animateUp') : setDirection('animateDown');
    }
  }

  return (
    <div>
      <img id='logo' src={logo} />
      <div id='mobileDiv' className={`${expandCss}`}
        onAnimationStart={() => setAnimStatus(true)}
        onAnimationEnd={() => setAnimStatus(false)}
      >
        <HamburgerSqueeze
          barColor="white"
          id='burgerBtn'
          isActive={isVisible}
          toggleButton={handleClickAnimation}
        />

        <Menu vertical id='mobileMenu'>
          <Link
            to="main"
            onClick={handleClickAnimation}
            smooth={true}
            duration={500}
          >
            <Menu.Item
              name='Main'
              className='customItem'
            />
          </Link>
          {
            menuItems.map(({ to, name, offset }, index) => (
              <CustomMenuItem
                key={index}
                name={name}
                to={to}
                onClick={handleClickAnimation}
                offset={offset}
              />
            ))
          }
        </Menu>
      </div>
    </div>
  );
};

export default MobileMenu;