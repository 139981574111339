import React, { useState } from 'react';
import { Container, Grid, Segment, Image, Visibility, Icon } from 'semantic-ui-react';
import Fade from 'react-reveal/Fade';

import '../CSS/about.css';
import sorin from '../images/sorin.png';
import gabriel from '../images/gabriel.jpg';


const About = ({ activeItem, setActive }) => {
  const [imageHoveredG, setImageHoveredG] = useState(false);
  const [imageHoveredS, setImageHoveredS] = useState(false);

  let applyStyleS = imageHoveredS ? 'keepStyle' : '';
  let applyStyleG = imageHoveredG ? 'keepStyle' : '';

  let stayHiddenS = imageHoveredS ? '' : 'stayHidden';
  let stayHiddenG = imageHoveredG ? '' : 'stayHidden';

  return (
    <Visibility
      once={false}
      onUpdate={(e, { calculations: { percentagePassed } }) => {
        if (percentagePassed * 100 <= 60 && percentagePassed !== 0) {
          setActive('aboutUs')
        }
      }}
    >
      <Segment basic id='aboutSegment' name='about'>
        <Container id='aboutContainer'>
          <Grid stackable id='aboutGrid'>
            <Grid.Row>
              <Grid.Column textAlign='center' verticalAlign='middle'>
                <h1 className='underlineHeader'>Meet the Team</h1>
                <p className='darkOnLightTextColor indepthAbout' style={{ margin: '0 auto' }}>
                  Clients choose to work with Filamind to take advantage of today’s technology to transform their idea into reality or create innovation to better serve their business goals. Our company consulting services include Custom Software Solutions, Mobile and Web Apps, Product Development, UI/UX Design and business analysis and process management. We are a Canadian owned and operated company located in the nation’s capital which services small to large enterprises and agencies in Canada and internationally.
                            </p>
              </Grid.Column>
            </Grid.Row>

            <Grid.Row columns={2} verticalAlign='middle'>
              <Grid.Column>
                <div className='centeredDiv'>
                  <div onMouseEnter={() => setImageHoveredS(true)} onMouseLeave={() => setImageHoveredS(false)}>
                    <Image className={`imageDescription ${applyStyleS}`} size='large' src={sorin} />
                    <Fade duration={500} top when={imageHoveredS}>
                      <h1 className={`overImageH1 ${stayHiddenS}`}>Sorin Vaduva</h1>
                    </Fade>
                    <Fade duration={500} bottom when={imageHoveredS}>
                      <p className={`overImageP ${stayHiddenS}`}>
                        Sorin Vaduva is a local Entrepreneur
                        from Ottawa. He has been doing sales and business development
                        for over 15 years.
                                        </p>
                    </Fade>
                  </div>
                  <h1 className='darkOnLightTextColor'>Sorin Vaduva</h1>
                  <p className='darkOnLightTextColor'>Business Development Manager</p>
                  <Icon
                    onClick={() => window.open('https://www.linkedin.com/in/sorin-claudiu-vaduva-cne®-sres®-02600640')}
                    link name='linkedin' size='big' className='coloredIcon' />
                </div>
              </Grid.Column>
              <Grid.Column>
                <div className='centeredDiv'>
                  <div onMouseEnter={() => setImageHoveredG(true)} onMouseLeave={() => setImageHoveredG(false)}>
                    <Image className={`imageDescription ${applyStyleG}`} size='large' src={gabriel} />
                    <Fade duration={500} top when={imageHoveredG}>
                      <h1 className={`overImageH1 ${stayHiddenG}`}>Gabriel Tapuc</h1>
                    </Fade>
                    <Fade duration={500} bottom when={imageHoveredG}>
                      <p className={`overImageP ${stayHiddenG}`}>
                        Gabriel is an experienced and enthusiastic web and mobile app developer who is passionate about innovating using technology.
                                    </p>
                    </Fade>
                  </div>
                  <h1 className='darkOnLightTextColor'>Gabriel Tapuc</h1>
                  <p className='darkOnLightTextColor'>Technical Lead</p>
                  <Icon
                    onClick={() => window.open('https://www.linkedin.com/in/gabrieltapuc/')}
                    name='linkedin' size='big' className='coloredIcon' />
                </div>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row >
              <Grid.Column textAlign='left'>
                <h1 className='titleAbout'>Our Mission</h1>
                <p className='darkOnLightTextColor indepthAbout'>
                  At Filamind, we strive to bring the best custom software solutions for new company ideas to help bridge the gap between idea and technology. Our team of digital experts is committed to innovation and creation of new products and ideas in the ever-changing world of software while providing state of the art warranty and service to our clients.
                            </p>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Container>
      </Segment>
    </Visibility>
  );
};

export default About;