import React, { useEffect, useState } from 'react';
import { Container, Grid, Segment, Image, Visibility } from 'semantic-ui-react';
import Rellax from 'react-rellax';

import '../CSS/warranty.css';

import squareOne from '../images/squareOne.svg';
import squareTwo from '../images/squareTwo.svg';

const Warranty = ({ activeItem, setActive }) => {
    const [screenWidth, setScreenWidth] = useState(769)


    // Required for proper compilation of Gatsby build
    useEffect(() => {
        if (typeof window !== `undefined`) {
            setScreenWidth(window.innerWidth);
        }
    }, []);

    return (
        <Visibility
            once={false}
            onBottomVisible={() => setActive('ourWarranty')}>
            <Segment basic id='warrantySegment' name='warranty'>
                <Rellax percentage={-2.9} speed={-1}>
                    <Image src={squareOne} size='small' id='squareOne' />
                </Rellax>
                <Rellax percentage={3.4} speed={1} >
                    <Image src={squareTwo} size='small' id='squareTwo' />
                </Rellax>

                <Container id='warContainer'>
                    <Grid id='warrGrid' stackable>
                        <Grid.Row id='topRow'>
                            <Grid.Column textAlign='center' verticalAlign='middle'>
                                <h1 className='underlineHeader'>Peace of Mind Guaranteed </h1>
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row columns='2' verticalAlign='middle'>
                            {screenWidth <= 768 ? <Grid.Column style={{ height: '25em' }}></Grid.Column> : ''}
                            <Grid.Column id='txtCol'>
                                <h1 className='inPageTitle' id='warrantyTitle'>The Filamind Warranty</h1>
                                <p style={{ color: '#2E4787', fontSize: '0.9em' }}>
                                    Filamind's state of the art warranty is in place for 12 months and covers all aspects of the custom software project to ensure it runs smoothly on completion and for years to come. Quality assurance from beginning to the end is at the core of our values, giving our clients peace of mind.
                            </p>
                            </Grid.Column>

                        </Grid.Row>
                    </Grid>
                </Container>
            </Segment>
        </Visibility>
    );
};

export default Warranty;