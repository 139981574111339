import React from 'react';
import { Container, Grid, Segment, Image, Visibility } from 'semantic-ui-react';
import Fade from 'react-reveal/Fade';

import '../CSS/services.css';

import monitorSvg from '../images/monitor.svg';
import mobileSvg from '../images/mobile.svg';
import consultingSvg from '../images/consulting.svg';

const Services = ({ activeItem, setActive }) => {
  return (
    <Visibility
      once={false}
      onBottomVisible={() => setActive('Services')}
    >
      <Segment basic id='servicesSegment' name='services'>
        <Container id='servicesContainer'>
          <Grid stackable id='servicesGrid'>
            <Grid.Row id='topRow'>
              <Grid.Column textAlign='center' verticalAlign='middle'>
                <h1 className='underlineHeader'>Our Services</h1>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row columns={3} verticalAlign='middle'>

              <Grid.Column textAlign='center'>
                <Fade timeout={1000} delay={150}>
                  <div>
                    <Image src={mobileSvg} size='tiny' className='centeredImage' />
                    <h2 className='darkOnLightTextColor'>App Development</h2>
                    <p className='darkOnLightTextColor'>
                      Mobile apps put your idea directly into the hands of your clients. They provide a great interface for users, customizable real-time data, scalability and more.
                                    </p>
                  </div>
                </Fade>
              </Grid.Column>
              <Grid.Column textAlign='center'>
                <Fade timeout={1000} delay={400}>
                  <div>
                    <Image src={monitorSvg} size='tiny' className='centeredImage' />
                    <h2 className='darkOnLightTextColor'>Web Development</h2>
                    <p className='darkOnLightTextColor'>
                      A mobile-friendly, attractive website combined with great user experience is key to a successful web presence. Automate business processes with custom web applications to take your business to the next level.
                                    </p>
                  </div>
                </Fade>
              </Grid.Column>
              <Grid.Column textAlign='center'>
                <Fade timeout={1000} delay={650}>
                  <div>
                    <Image src={consultingSvg} size='tiny' className='centeredImage' />
                    <h2 className='darkOnLightTextColor'>Consulting Services</h2>
                    <p className='darkOnLightTextColor'>
                      Our Software team is excited to learn more
                      about your business processes and help in creating
                      custom software products that can help your company save time and money.
                                    </p>
                  </div>
                </Fade>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Container>
      </Segment>
    </Visibility>
  );
};

export default Services;