import React, { useState } from 'react'
import { Helmet } from 'react-helmet'
import Main from '../components/Main.js';
import Menu from '../components/menu'
import DesktopMenu from '../components/menu/DesktopMenu.js';
import MobileMenu from '../components/menu/MobileMenu.js';
import Footer from '../components/Footer.js';
import SquareBtn from '../components/reusables/SquareBtn.js';
import Contact from '../components/Contact.js';

const MainLayout = ({ components, articles, children }) => {
  const [activeItem, setActive] = useState('Main')

  const phoneSqrInfo = [(<span id='inBubbleTxt'>Call us at: <br />(613) 262-9562</span>), 'phone square', ['143px', '107px']];
  const mailSqrInfo = [(<span id='inBubbleTxt'>Email us at: <br />sorin.vaduva@filamind.io</span>), 'mail square', ['200px', '135px']];

  const siteName = 'https://filamind.io/'

  return (
    <React.Fragment>
      <Helmet>
        <meta property="og:image" content={siteName + 'ogImage.png'} />

        <meta name="twitter:site" content="@filamind" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:image" content={siteName + 'ogImage.png'} />
      </Helmet>
      <div className='app-container'>
        <SquareBtn
          text={phoneSqrInfo[0]}
          iconName={phoneSqrInfo[1]}
          distance={phoneSqrInfo[2]}
        />
        <SquareBtn
          text={mailSqrInfo[0]}
          iconName={mailSqrInfo[1]}
          distance={mailSqrInfo[2]}
        />
        <Menu setActive={setActive} showBlog={articles.length > 0} />
        <Main activeItem={activeItem} setActive={setActive} />
        {
          components.map((component, index) => {
            return React.createElement(
              component,
              { activeItem, setActive, key: index }
            )
          })
        }
        {children}
        <Contact
          activeItem={activeItem}
          setActive={setActive}
        />
        <Footer activeItem={activeItem} setActive={setActive} />
      </div>
    </React.Fragment>
  )
}

export default MainLayout