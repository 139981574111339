import React, { useState } from 'react';
import ProjectModal from '../reusables/ProjectModal.js';

import '../../CSS/reusablesCSS/customCard.css';

const CustomCard = ({ thumbTxt, thumbnail, title, imgArr, desc, link, hrefLink }) => {
    const [isOpen, setOpen] = useState('initial');

    let handleClick = (status) => {
        setOpen(status);
        document.body.style.overflow = (status ? 'hidden' : 'auto');
    }

    return (
        <div>
            <ProjectModal
                link={link}
                hrefLink={hrefLink}
                desc={desc} title={title}
                imgArr={imgArr}
                isOpen={isOpen} setOpen={handleClick} />
            <div className='crdContainer' onClick={() => handleClick(true)}>
                <div style={{ backgroundImage: `url(${thumbnail})` }} className='crdImg' />
                <div className="overlay">
                    <div className="crdTxt">{thumbTxt}</div>
                </div>
            </div>
        </div>
    );
};

export default CustomCard;
