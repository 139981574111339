import React, { useEffect } from 'react';
import CustomCard from './reusables/CustomCard.js';
import { Container, Grid, Segment, Visibility } from 'semantic-ui-react';

import '../CSS/portfolio.css';

import m_neappoli from '../images/projectsImages/neappoli/Home.png';
import a_neappoli from '../images/projectsImages/neappoli/About.png';
import b_neappoli from '../images/projectsImages/neappoli/Blog.png';
import d_neappoli from '../images/projectsImages/neappoli/Dashboard.png';
import s_neappoli from '../images/projectsImages/neappoli/Support.png';
import mobile_neappoli from '../images/projectsImages/neappoli/mobileImages.png';

import m_sfmatei from '../images/projectsImages/sfmatei/main.png';

import ps_aedo from '../images/projectsImages/aedo/projects.png';
import p_aedo from '../images/projectsImages/aedo/project.png';
import mobile_aedo from '../images/projectsImages/aedo/mobile_aedo.png';

import neap_thumbnail from '../images/projectsImages/neappoli/neap_thumbnail.png';
import sf_thumbnail from '../images/projectsImages/sfmatei/sfmat_thumbnail.png';
import aedo_thumbnail from '../images/projectsImages/aedo/thumbnail.jpg';

import pit_thumbnail from '../images/projectsImages/myPitBoard/thumbnail.png';
import onePit from '../images/projectsImages/myPitBoard/firstPic.png';
import secondPit from '../images/projectsImages/myPitBoard/secondPic.png';

const Portfolio = ({ activeItem, setActive }) => {
  let neappoliImgArr = [mobile_neappoli, m_neappoli, d_neappoli, b_neappoli, a_neappoli, s_neappoli];
  let sfMateiImgArr = [m_sfmatei];
  let aedoImgArr = [mobile_aedo, p_aedo, ps_aedo];
  let pitArr = [onePit, secondPit];

  return (
    <Visibility
      once={false}
      onBottomVisible={() => setActive('portfolio')}>
      <Segment basic id='porfolioSegment' name='portfolio'>
        <Container id='portfolioContainer'>
          <Grid stackable id='portfolioGrid'>
            <Grid.Row>
              <Grid.Column textAlign='center' verticalAlign='middle'>
                <h1 className='invertedUnderlineHeader'>Our Work, Our Pride</h1>
                <p id='portText'>
                  We value the relationships we build and proud of the
                  work we do for our clients from many different
                  sectors. Check out some of our current and past work
                            </p>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row columns={4} verticalAlign='middle'>
              <Grid.Column>
                <CustomCard
                  thumbnail={neap_thumbnail}
                  thumbTxt={'Neappoli'}
                  imgArr={neappoliImgArr}
                  link={'neappoli.com'}
                  hrefLink={'https://www.neappoli.com'}
                  title={'Neappoli'}
                  desc={`
                    Neappoli is a mobile application that makes the process of submitting city issues very convenient: in a few taps, users will be able to submit non-urgent 3-1-1 issues without having to call the city. In a few taps, they can send and track a geotagged issue that has all the required information the city requires. Neappoli was built using Firebase and Flutter for reliability and cost saving purposes. It is currently serving the citizens of our national capital, Ottawa.
                  `}
                  className='customCard' />
              </Grid.Column>
              <Grid.Column>
                <CustomCard
                  thumbnail={sf_thumbnail}
                  title={'St Matei Romanian Orthodox Church'}
                  imgArr={sfMateiImgArr}
                  link={'sfmatei.ca'}
                  hrefLink={'https://sfmatei.ca'}
                  desc={`
                    Built a responsive website in React (and Gatsby) for the St. Matthew and St. Hristina Romanian Orthodox church. The website can be viewed here: https://sfmatei.ca. It features: A photo gallery with a mechanism that makes it easy to upload new albums, Various events and posts pages which content is easy to add. edit and remove, A dynamic event notice that shows the upcoming events based on the schedule set by the minister, and a multiple language support.
                  `}
                  thumbTxt={'Romanian Orthodox Church'}
                  className='customCard' />
              </Grid.Column>

              <Grid.Column>
                <CustomCard
                  thumbnail={aedo_thumbnail}
                  title='Aedo'
                  imgArr={aedoImgArr}
                  link='aedo.ai'
                  hrefLink='https://aedo.ai'
                  desc={`
                    Foreman app (mobile app): The Foreman App is used by foremen in the field to easily give an update and a time estimate of the work that happens at the construction site. The Foreman App is used hand in hand with the Project Manager(here, add the link to the PM app) app, a web version of the app for project managers. The Foreman App was built with Flutter and works with a GraphQL back-end. Project Manager app (web app): The Project Manager app is the project manager's view of the Foreman App (add link). It allows project managers to manage construction projects and stay updated with what is happening on the construction site. The PM app works in conjunction with the Foreman App. It was built in React using a GraphQL back-end.
                  `}
                  thumbTxt={'Aedo'}
                  className='customCard' />
              </Grid.Column>
              <Grid.Column>
                <CustomCard
                  thumbnail={pit_thumbnail}
                  title={'MyPitboard'}
                  imgArr={pitArr}
                  hrefLink='https://mypitboard.com'
                  link='mypitboard.com'
                  desc={`
                    The MyPitboard app is a companion mobile app built with Flutter that allows users to connect to their MyPitboard device. The device allows motocross riders to get instantaneous feedback anywhere on the track. This companion mobile app is intended to sync the MyPitboard device to the phone to save and view various statistics about the runs recording on the motocross track.
                  `}
                  thumbTxt={'MyPitBoard'}
                  className='customCard' />
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Container>
      </Segment>
    </Visibility>
  );
};

export default Portfolio;