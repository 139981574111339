import React from 'react'
import PropTypes from 'prop-types'
import DesktopMenu from './DesktopMenu'
import MobileMenu from './MobileMenu'

const MenuIndex = ({ setActive, showBlog }) => {
  const menuItems = [
    {
      name: 'Services',
      to: 'services',
      onClick: () => setActive('Services'),
      offset: -50
    }, {
      name: 'Filamind Warranty',
      to: 'warranty',
      onClick: () => setActive('ourWarranty'),
      offset: -50 //for mobile menu
    }, {
      name: 'Our Work',
      to: 'portfolio',
      onClick: () => setActive('portfolio'),
    }, {
      name: 'About Us',
      to: 'about',
      onClick: () => setActive('aboutUs'),
    },
  ]

  if (showBlog) {
    menuItems.push({
      name: 'Blog',
      to: 'blog',
      onClick: () => setActive('blog')
    })
  }

  menuItems.push({
    name: 'Contact Us',
    to: 'contact',
    onClick: () => setActive('contactUs'),
  })

  return (
    <div>
      <DesktopMenu menuItems={menuItems} />
      <MobileMenu menuItems={menuItems} />
    </div>
  )
}

MenuIndex.propTypes = {
  setActive: PropTypes.func.isRequired,
  showBlog: PropTypes.bool.isRequired,
}

export default MenuIndex