import React from 'react'
import { Menu, Container } from 'semantic-ui-react'
import { Link } from 'react-scroll'

import logo from '../../images/desktopLogo.png'
import CustomMenuItem from './CustomMenuItem'

import '../../CSS/desktopMenu.css'

const DesktopMenu = ({ menuItems }) => {

  return (
    <Menu size='large' inverted pointing secondary id='menu'>
      <Container>
        <Menu.Menu position='right'>
          <Menu.Item>
            <img id='desktopLogo' src={logo} />
          </Menu.Item>
          {
            menuItems.map(({ name, to, onClick }) => (
              <CustomMenuItem
                key={name}
                name={name}
                to={to}
                onClick={onClick}
              />
            ))
          }
        </Menu.Menu>
      </Container>
    </Menu>
  )
}

export default DesktopMenu