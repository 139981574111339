import React, { useRef } from 'react';

import '../../CSS/reusablesCSS/ProjectModal.css';
import { Icon } from 'semantic-ui-react';

const ProjectModal = ({ isOpen, setOpen, title, imgArr, desc, link, hrefLink }) => {
    const ref = useRef();

    let direction;
    if (isOpen === 'initial') {
        direction = 'initial';
    } else {
        direction = isOpen ? 'open' : 'close';
    }

    const onClickAway = (e) => {
        if (e.target === ref.current) {
            setOpen(false);
        }
    }

    let generateImages = () => {
        return imgArr.map((img, index) => <img src={img} key={index} className='modalImg' />)
    }

    return (

        <div className={`modalOverlay ${direction}`} ref={ref} onClick={(e) => onClickAway(e)}>
            <div className='modalContainer'>
                <Icon name='x' color='red' size='large' onClick={() => setOpen(false)} />
                <h1 className='modTitle' >{title}</h1>
                <a href={hrefLink} target="_blank" className='websiteLink'>{link}</a>
                <p className='darkOnLightTextColor modDescription'>
                    {desc}
                </p>
                {generateImages()}
            </div>
        </div>

    );
};

export default ProjectModal;
