import React, {useState} from 'react';
import {Icon} from 'semantic-ui-react';

import '../../CSS/reusablesCSS/SquareBtn.css';

const SquareBtn = ({text, iconName, distance}) => {
    const [isHovered, setHovered] = useState(false)

    let displayStatus = isHovered ? 'show' : 'hide';
    return (
        <div id='bubbleContainer'>
            <div  
                onMouseEnter={() => setHovered(true)}
                onMouseLeave={() => setHovered(false)} 
                className={displayStatus} 
                id='bubble' style={{right: distance[1]}}>
                {text}
            </div>
            <Icon  
                onMouseEnter={() => setHovered(true)}
                onMouseLeave={() => setHovered(false)} 
                id='bubbleIcon' size='huge' 
                name={iconName} style={{right: distance[0]}}/> 
        </div>
    )
};

export default SquareBtn;