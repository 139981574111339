import React from 'react';
import { Container, Grid, Segment, Menu, Icon } from 'semantic-ui-react';
import { Link } from 'react-scroll';

import '../CSS/footer.css';

const Contact = ({ activeItem, setActive }) => {
  return (
    <div name='footer'>
      <Segment basic id='footerSegment'>
        <Container>
          <Grid divided inverted id='footerGrid'>
            <Grid.Row centered>
              <Grid.Column width={8}>
                <Menu text vertical inverted>
                  <Link
                    to="main"
                    onClick={() => setActive('Main')}
                    smooth={true}
                    duration={500} >
                    <Menu.Item
                      className='footerItem'
                      name='Main'
                      active={activeItem === 'Main'} />
                  </Link>
                  <Link
                    to="services"
                    onClick={() => setActive('Services')}
                    smooth={true}
                    duration={500}>
                    <Menu.Item
                      className='footerItem'
                      name='Services'
                      active={activeItem === 'Services'} />
                  </Link>
                  <Link
                    to="warranty"
                    onClick={() => setActive('ourWarranty')}
                    smooth={true}
                    duration={500}>
                    <Menu.Item
                      className='footerItem'
                      name='Filamind Warranty'
                      active={activeItem === 'ourWarranty'} />
                  </Link>
                  <Link
                    to="portfolio"
                    onClick={() => setActive('portfolio')}
                    smooth={true}
                    duration={500}>
                    <Menu.Item
                      className='footerItem'
                      name='Our Work'
                      active={activeItem === 'portfolio'} />
                  </Link>
                  <Link
                    to="about"
                    onClick={() => setActive('aboutUs')}
                    smooth={true} duration={500}>
                    <Menu.Item
                      className='footerItem'
                      name='About Us'
                      active={activeItem === 'aboutUs'} />
                  </Link>

                </Menu>
              </Grid.Column>
              <Grid.Column width={8}>
                <div style={{ marginLeft: '3em' }}>
                  <h2 style={{ color: '#EFEFEF' }}>Social Media</h2>
                  <Icon
                    onClick={() => window.open('https://www.facebook.com/Filamind-Software-107182120927822/')}
                    link
                    name='facebook'
                    size='big'
                    className='coloredIcon' />
                  <Icon
                    onClick={() => window.open('https://twitter.com/filamind')}
                    link
                    name='twitter square'
                    size='big'
                    className='coloredIcon' />
                  <Icon
                    onClick={() => window.open('https://instagram.com/filamindcustomsoftware?igshid=1937zrs840gbr')}
                    link
                    name='instagram'
                    size='big'
                    className='coloredIcon' />
                </div>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Container>
      </Segment>
      <Segment basic id='copyright'>
        <Container>
          Copyright 2020 Filamind Inc. | All rights reserved
            </Container>
      </Segment>
    </div>
  );
};

export default Contact;