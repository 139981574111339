import React, { useEffect, useRef } from 'react';
import { Container, Segment, Button } from 'semantic-ui-react';
import Fade from 'react-reveal/Fade';
import { Link } from 'react-scroll';

import { pixiEffect } from './pixiEffect.js';

import '../CSS/main.css';

const Main = ({ activeItem, setActive }) => {
  const pixieApp = useRef(null);

  let height;
  let width;

  if (typeof window !== `undefined`) {
    height = window.innerHeight;
    width = window.innerWidth;
  }

  useEffect(() => {
    // Calling pixi effect here to be able to have the canvas appear in segment
    pixieApp.current = pixiEffect(height, width);
    return () => {
      if (pixieApp.current !== null) {
        pixieApp.current.destroy();
      }
    }
  }, []);

  return (
    <Fade>
      <Segment basic id='mainSegment' name='main'>
        <Container>
          <div id='mainInfo'>
            <h1 id='titleText'>Software done right.</h1>
            <p id='introText'>
              An experienced team with proven results for all your software needs.
                        </p>
            <Link
              to="contact"
              onClick={() => setActive('contactUs')}
              smooth={true}
              duration={500}>
              <Button inverted size='huge' id='invertedBtn'>
                Tell Us About Your Project
                                </Button>
            </Link>
          </div>
        </Container>
        <canvas id='pixiRenderArea' width={width} height={height}></canvas>
      </Segment>
    </Fade>
  );
};

export default Main;