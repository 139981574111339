import React, { useState } from "react";
import Services from '../components/Services.js';
import Warranty from '../components/Warranty.js';
import Portfolio from '../components/Portfolio.js';
import About from '../components/About.js';
import Contact from '../components/Contact.js';
import Blog from '../components/Blog.js';

import ReactNotification from 'react-notifications-component';
import 'react-notifications-component/dist/theme.css';

import { Helmet } from "react-helmet";
import { graphql } from 'gatsby'

import 'semantic-ui-css/semantic.min.css';
import '../CSS/generalCSS.css';
import MainLayout from "../layouts/MainLayout.js";

const IndexPage = ({ data }) => {
  const articles = data.allArticle?.edges || []
  let componentsArr = [
    Services,
    Warranty,
    Portfolio,
    About,
  ];

  const title = 'Filamind Software';
  const description = 'At Filamind we strive to bring the best custom software solutions for new company ideas to help bridge the gap between idea and technology.';

  return (
    <div className="app-container">
      <Helmet>
        <title>{title}</title>
        <meta name='description' content={description} />

        <meta property="og:title" content={title} />
        <meta property="og:description" content={description} />

        <meta name="twitter:description" content={description} />
        <meta name="twitter:image:alt" content={description} />
        <meta name="twitter:title" content={title} />
      </Helmet>
      <ReactNotification />
      <MainLayout components={componentsArr} articles={articles}>
        {
          articles.length > 0 && <Blog articles={articles} />
        }
      </MainLayout>
    </div>
  );
};

export const query = graphql`
query {
  allArticle(filter: {id: {ne: "templateArticle"}}) {
    edges {
      node {
        title
        content
        featureImageUrl
        summary
        fields {
          slug
        }
      }
    }
  }
}
`

export default IndexPage